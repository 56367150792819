/* eslint-disable class-methods-use-this */
import moment from 'moment';
import $ from 'jquery';
import 'daterangepicker';

class HolidayDatePicker {
  constructor(userOptions) {
    const options = { ...HolidayDatePicker.defaults(userOptions), ...userOptions };
    this.today = moment(options.today, 'YYYY.MM.DD');
    // Two years seems a reasonable limit for the datepicker since we cache availability
    // for 18 months but other external providers may have availability for longer.
    this.maxDate = this.today.clone().add(2, 'years');
    this.validCheckinDates = options.validCheckinDates;
    this.validCheckoutDates = options.validCheckoutDates;
    this.checkinDisplayElParent = options.checkinDisplayElParent;
    this.checkoutDisplayElParent = options.checkoutDisplayElParent;
    this.checkinDisplayEl = options.checkinDisplayEl;
    this.checkoutDisplayEl = options.checkoutDisplayEl;
    this.validMinstays = options.validMinstays;
    this.messageEl = options.messageEl;
    this.checkoutAutoFill = options.checkoutAutoFill;

    this.checkinValueEl = options.checkinValueEl;
    this.checkoutValueEl = options.checkoutValueEl;
    this.minStay = options.minStay;

    this.onCheckinChange = this.onCheckinChange.bind(this);
    this.onCheckoutChange = this.onCheckoutChange.bind(this);
    this.isWithinMinStay = this.isWithinMinStay.bind(this);
    this.convertParamDate = this.convertParamDate.bind(this);
    this.isInvalidCheckin = this.isInvalidCheckin.bind(this);
    this.isInvalidCheckout = this.isInvalidCheckout.bind(this);
    this.validCheckinsExist = this.validCheckinsExist.bind(this);
    this.validCheckoutsExist = this.validCheckoutsExist.bind(this);

    this.init(options.startDate, options.endDate);
  }

  static defaults(options) {
    return {
      startDate: null,
      endDate: null,
      minStay: 0,
      checkinDisplayEl: options.checkinDisplayElParent,
      checkoutDisplayEl: options.checkoutDisplayElParent,
      validCheckinDates: null,
      validCheckoutDates: null,
      validMinstays: null,
      minstayEl: null,
      checkoutAutoFill: false,
    };
  }

  init(startDate, endDate) {
    const { minStay } = this;
    this.checkinDate = this.convertParamDate(startDate);
    let checkoutDate = this.convertParamDate(endDate);

    const checkinPresentAndValid = this.checkinDate
      && this.validCheckinDates && this.validCheckinsExist();

    if (checkinPresentAndValid && this.isInvalidCheckin(this.checkinDate)) {
      const firstValidCheckin = moment(this.validCheckinDates[0], 'YYYY.MM.DD');
      const lastValidCheckin = moment(this.validCheckinDates[this.validCheckinDates.length - 1], 'YYYY.MM.DD');
      if (this.checkinDate.isBefore(firstValidCheckin)) {
        this.checkinDate = moment(firstValidCheckin, 'YYYY.MM.DD');
      }
      if (this.checkinDate.isAfter(lastValidCheckin)) {
        this.checkinDate = moment(lastValidCheckin, 'YYYY.MM.DD');
      }
    }

    if (this.checkinDate && this.validCheckinsExist() && !this.validCheckinDates.length) {
      this.checkinDate = undefined;
    }

    if (!this.checkinDate && checkoutDate && this.isInvalidCheckout(checkoutDate)) {
      checkoutDate = undefined;
    }

    if (this.checkinDate && checkoutDate && this.isInvalidCheckout(checkoutDate)) {
      const formattedCheckinDate = this.checkinDate.format('YYYY.MM.DD');
      const validCheckoutDates = this.validCheckoutDates[formattedCheckinDate];
      if (!validCheckoutDates) {
        checkoutDate = undefined;
      } else {
        const firstValidCheckout = moment(validCheckoutDates[0], 'YYYY.MM.DD');
        const lastValidCheckout = moment(validCheckoutDates[validCheckoutDates.length - 1], 'YYYY.MM.DD');
        if (checkoutDate.isBefore(firstValidCheckout)) {
          checkoutDate = moment(firstValidCheckout, 'YYYY.MM.DD');
        }
        if (checkoutDate.isAfter(lastValidCheckout)) {
          checkoutDate = moment(lastValidCheckout, 'YYYY.MM.DD');
        }
      }
    }

    if (this.checkinDate && checkoutDate) {
      if (this.isWithinMinStay(this.checkinDate, checkoutDate)
      || this.checkinDate.isAfter(checkoutDate)) {
        checkoutDate = this.checkinDate.clone().add({ days: minStay });
      }
    } else if (checkoutDate && minStay) {
      const minStayFromToday = this.today.clone().add({ days: minStay });
      checkoutDate = checkoutDate.isBefore(minStayFromToday) ? minStayFromToday : checkoutDate;
    }

    this.initializeCheckinDate(this.checkinDate);
    this.initializeCheckoutDate(checkoutDate);
  }

  convertParamDate(paramDate) {
    if (paramDate) {
      const date = moment(paramDate, 'YYYY.MM.DD');
      const today = this.today.clone();
      return date.isBefore(today) ? today : date;
    }
    return undefined;
  }

  validCheckinsExist() {
    return Array.isArray(this.validCheckinDates);
  }

  validCheckoutsExist() {
    return this.validCheckinsExist() && this.validCheckoutDates;
  }

  isInvalidCheckin(date) {
    if (!this.validCheckinsExist()) { return false; }
    const formattedDate = date.format('YYYY.MM.DD');
    return !this.validCheckinDates.includes(formattedDate);
  }

  isInvalidCheckout(date) {
    if (!this.validCheckoutsExist()) { return false; }

    const formattedDate = date.format('YYYY.MM.DD');
    let checkinDate = this.getDate(this.checkinValueEl) || this.checkinDate;

    if (!checkinDate) return true;
    checkinDate = checkinDate.format('YYYY.MM.DD');

    const validDates = this.validCheckoutDates[checkinDate];
    if (!validDates) return true;
    return !validDates.includes(formattedDate);
  }

  initializeCheckinDate(momentDate) {
    if (momentDate) {
      this.setDate(momentDate, this.checkinDisplayEl, this.checkinValueEl);
    }
    this.initializeCheckinDatepicker(momentDate);
  }

  initializeCheckoutDate(momentDate) {
    if (momentDate) {
      this.setDate(momentDate, this.checkoutDisplayEl, this.checkoutValueEl);
    }
    this.initializeCheckoutDatepicker(momentDate);
  }

  getDate(element) {
    let value = $(element).val();
    value = value.slice(4, 15);
    return value ? moment(value, 'MMM DD YYYY') : null;
  }

  setDate(momentDate, displayEl, valueEl) {
    $(valueEl).val(momentDate.toString());
    $(displayEl).html(momentDate.format('DD MMM YYYY'));
    $(displayEl).css('color', 'black');
  }

  setMessage(message) {
    if (this.messageEl) {
      $(this.messageEl).html(`<p class="calendar-message">${message}</p>`);
    }
  }

  clearMessage() {
    if (this.messageEl) {
      $(this.messageEl).empty();
    }
  }

  getInvalidCheckoutMessage(invalidDate, validDate) {
    return `<b>${invalidDate.format('DD MMM YYYY')}</b> is not a valid checkout date.
    The closest valid checkout date is <b>${validDate.format('DD MMM YYYY')}</b>`;
  }

  isWithinMinStay(checkinDate, checkoutDate) {
    return Math.abs(checkinDate.diff(checkoutDate, 'days')) < this.minStay;
  }

  initializeCheckinDatepicker(startDate) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const picker = this;
    $(this.checkinDisplayElParent).daterangepicker({
      autoApply: true,
      opens: 'center',
      showDropdowns: true,
      singleDatePicker: true,
      maxDate: this.maxDate,
      minDate: this.today.clone(),
      startDate: startDate || this.today.clone(),
      isInvalidDate: picker.isInvalidCheckin,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (start, end, label) => {
      picker.setDate(start, picker.checkinDisplayEl, picker.checkinValueEl);
      const checkoutDate = picker.getDate(picker.checkoutValueEl);
      picker.onCheckinChange(start, checkoutDate);
    });
  }

  initializeCheckoutDatepicker(startDate) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const picker = this;
    const { minStay } = this;
    const minDate = minStay ? this.today.clone().add({ days: minStay }) : this.today.clone();
    $(this.checkoutDisplayElParent).daterangepicker({
      autoApply: true,
      opens: 'center',
      showDropdowns: true,
      singleDatePicker: true,
      maxDate: this.maxDate,
      minDate,
      startDate: startDate || minDate,
      isInvalidDate: picker.isInvalidCheckout,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (start, end, label) => {
      picker.setDate(start, picker.checkoutDisplayEl, picker.checkoutValueEl);
      const checkinDate = picker.getDate(picker.checkinValueEl);
      picker.onCheckoutChange(start, checkinDate);
    });
  }

  onCheckinChange(checkinDate, checkout) {
    this.clearMessage();
    let checkoutDate = checkout;
    if (this.validMinstays) {
      const formattedCheckinDate = checkinDate.format('YYYY.MM.DD');
      const minStay = this.validMinstays[formattedCheckinDate];
      this.minStay = minStay;
    }

    if (this.minStay) {
      const message = `There is a minimum stay of <b>${this.minStay}</b> day(s) on this date.`;
      this.setMessage(message);
    }

    if (!checkoutDate && this.checkoutAutoFill) {
      checkoutDate = checkinDate.clone().add({ days: this.minStay });
      this.setDate(checkoutDate, this.checkoutDisplayEl, this.checkoutValueEl);
      this.changeDatepickerDates(this.checkoutDisplayElParent, checkoutDate);
    }

    if (this.validCheckinDates && this.validCheckoutDates && this.validCheckinDates.length > 0) {
      const formattedCheckinDate = checkinDate.format('YYYY.MM.DD');
      const formattedCheckoutDate = checkoutDate.format('YYYY.MM.DD');
      const validCheckouts = this.validCheckoutDates[formattedCheckinDate];
      if (!validCheckouts.includes(formattedCheckoutDate) || checkoutDate.is) {
        const firstValidCheckout = moment(validCheckouts[0], 'YYYY.MM.DD');
        const lastValidCheckout = moment(validCheckouts[validCheckouts.length - 1], 'YYYY.MM.DD');
        if (checkoutDate.isBefore(firstValidCheckout)) {
          checkoutDate = firstValidCheckout;
        }
        if (checkoutDate.isAfter(lastValidCheckout)) {
          checkoutDate = lastValidCheckout;
        }
        this.setDate(checkoutDate, this.checkoutDisplayEl, this.checkoutValueEl);
        this.changeDatepickerDates(this.checkoutDisplayElParent, checkoutDate);
      }
    }

    if (checkoutDate && (checkoutDate.isBefore(checkinDate)
    || this.isWithinMinStay(checkinDate, checkoutDate))) {
      checkoutDate = checkinDate.clone().add({ days: this.minStay });
      this.setDate(checkoutDate, this.checkoutDisplayEl, this.checkoutValueEl);
      this.changeDatepickerDates(this.checkoutDisplayElParent, checkoutDate);
    }

    this.setDate(checkinDate, this.checkinDisplayEl, this.checkinValueEl);
    this.changeDatepickerDates(this.checkinDisplayElParent, checkinDate);
  }

  onCheckoutChange(checkout, checkin) {
    this.clearMessage();
    let checkoutDate = checkout;
    let checkinDate = checkin;
    const minStayFromToday = this.today.clone().add({ days: this.minStay });
    if (checkoutDate.isBefore(minStayFromToday)) {
      const message = this.getInvalidCheckoutMessage(checkoutDate, minStayFromToday);
      this.setMessage(message);
      checkoutDate = minStayFromToday;
    }

    if (this.validCheckinDates && this.validCheckoutDates && this.validCheckinDates.length > 0) {
      const formattedCheckinDate = checkinDate.format('YYYY.MM.DD');
      const formattedCheckoutDate = checkoutDate.format('YYYY.MM.DD');
      const validCheckouts = this.validCheckoutDates[formattedCheckinDate];
      if (!validCheckouts.includes(formattedCheckoutDate)) {
        const lastValidCheckout = moment(validCheckouts[validCheckouts.length - 1], 'YYYY.MM.DD');
        const firstValidCheckout = moment(validCheckouts[0], 'YYYY.MM.DD');
        if (checkoutDate.isBefore(firstValidCheckout)) {
          const message = this.getInvalidCheckoutMessage(checkoutDate, firstValidCheckout);
          this.setMessage(message);
          checkoutDate = firstValidCheckout;
        }
        if (checkoutDate.isAfter(lastValidCheckout)) {
          const message = this.getInvalidCheckoutMessage(checkoutDate, lastValidCheckout);
          this.setMessage(message);
          checkoutDate = lastValidCheckout;
        }
      }
      this.setDate(checkoutDate, this.checkoutDisplayEl, this.checkoutValueEl);
      this.changeDatepickerDates(this.checkoutDisplayElParent, checkoutDate);
      return;
    }
    if (checkinDate && (checkoutDate.isBefore(checkinDate)
    || this.isWithinMinStay(checkinDate, checkoutDate))) {
      checkinDate = checkoutDate.clone().subtract({ days: this.minStay });
      this.changeDatepickerDates(this.checkinDisplayElParent, checkinDate);
      this.setDate(checkinDate, this.checkinDisplayEl, this.checkinValueEl);
    }

    this.setDate(checkoutDate, this.checkoutDisplayEl, this.checkoutValueEl);
    this.changeDatepickerDates(this.checkoutDisplayElParent, checkoutDate);
  }

  changeDatepickerDates(element, date) {
    const picker = $(element).data('daterangepicker');
    picker.setStartDate(date);
    picker.setEndDate(date);
  }
}

window.HolidayDatePicker = HolidayDatePicker;

export default HolidayDatePicker;
